<template>
  <div id="content"></div>
</template>

<script>
export default {
  name: 'Policy',
  components: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  margin: 0;
  padding: 0;
  line-height: 130%;
}

body {
  padding: 0.32rem 0.3rem 0 0.3rem;
}

a {
  text-decoration: none;
  color: #ffffff;
}

p {
  font-size: 0.24rem;
  color: #636566;
  margin-bottom: 0.16rem;
  // line-height: 0.36rem;
}

#content {
  width: 1000px;
  margin: 10px auto;
}

.text-right {
  text-align: right;
}

.black-color {
  color: #313333;
}

// .line-18 {
//   line-height: 0.36rem;
// }

.p-title {
  font-size: 0.34rem;
  color: #313333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.32rem;
}

.p-sub-title {
  font-size: 0.28rem;
  color: #313333;
  font-weight: 600;
  // line-height: 0.4rem;
}
</style>